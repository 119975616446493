<template>
	<div class="">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div v-else>
			<div class="main-content">
				<div class="header bg-white border-bottom">
					<div class="container-fluid">
						<div class="header-body border-0">
							<div class="row align-items-end">
								<div class="col">
									<h6 class="header-pretitle">Carousel Overview</h6>
									<div
										class="d-flex justify-content-between align-items-center"
										>
										<h2 class="">{{ carousel?.name ?? 'N/A' }}</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid">
					<router-link
						to="/games&campaigns/carousel"
						class="btn btn-light mb-4 btn-sm"
						>
						Go back
					</router-link>
					<div class="row">
						<div class="col-12 col-xl-6">
							<div class="card">
								<div class="card-body p-2">
									<div>
										<div style="padding: 1rem 1.5rem">
											<h4>Carousel Information</h4>
										</div>
										<div class="list-group list-group-flush mb-4">
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase">Title</span>
													</div>
													<div class="col-auto">
														<span class="">{{ carousel?.name ?? 'N/A' }}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Date created</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{
															carousel?.created_date ?? 'N/A'
														}}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Status</span
															>
													</div>
													<div class="col-auto">
														{{ carousel.status ? 'Enabled' : 'Disabled' }}
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>End Date</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{
															carousel?.end_date ?? 'N/A'
														}}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Linked Banner</span
															>
													</div>
													<div class="col-auto">
														<span>N/A</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12 col-xl-6">
							<div class="card">
								<div
									class="card-header d-flex justify-content-between align-items-center"
									>
									<h4 class="card-header-title">Carousel Images</h4>
								</div>
								<div class="card-body wrapper">
									<div
										id="preview"
										v-for="(asset, index) in carousel?.assets"
										:key="index"
										>
										<img
											alt="imagePreview"
											class="imageDimensions"
											:src="asset?.url"
											/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ShowCarousel',
  props: {
    carouselId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      carousel: {},
      loading: false
    }
  },
  created () {
    this.loadCarousel()
  },
  methods: {
    loadCarousel () {
      this.loading = true
      this.axios
        .get(`/v1/dynamic-dashboard/carousels/${this.carouselId}}`)
        .then((res) => {
          this.carousel = this.refactorCarouselData(res.data)
        })
        .catch(() => {
          this.$toastr.e('Something weent wrong', 'Error')
        })
        .finally(() => {
          this.loading = false
        })
    },
    refactorCarouselData (payload) {
      const carouselObject = {
        name: payload.name,
        created_date: moment(payload.created_at).format('YYYY-MM-DD'),
        status: payload.status === 'active',
        end_date: moment(payload.end_date).format('YYYY-MM-DD'),
        resolved_dynamic_urls: payload.resolved_dynamic_urls,
        dynamic_urls: payload.dynamic_urls,
        assets: payload.assets
      }

      return carouselObject
    }
  }
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
}

.imageDimensions {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
